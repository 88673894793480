exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-development-detail-jsx": () => import("./../../../src/pages/development-detail.jsx" /* webpackChunkName: "component---src-pages-development-detail-jsx" */),
  "component---src-pages-developments-jsx": () => import("./../../../src/pages/developments.jsx" /* webpackChunkName: "component---src-pages-developments-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-calculator-landing-template-js": () => import("./../../../src/templates/calculator-landing-template.js" /* webpackChunkName: "component---src-templates-calculator-landing-template-js" */),
  "component---src-templates-career-details-js": () => import("./../../../src/templates/career-details.js" /* webpackChunkName: "component---src-templates-career-details-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-development-details-template-js": () => import("./../../../src/templates/development-details-template.js" /* webpackChunkName: "component---src-templates-development-details-template-js" */),
  "component---src-templates-development-listing-template-js": () => import("./../../../src/templates/development-listing-template.js" /* webpackChunkName: "component---src-templates-development-listing-template-js" */),
  "component---src-templates-development-main-listing-template-js": () => import("./../../../src/templates/development-main-listing-template.js" /* webpackChunkName: "component---src-templates-development-main-listing-template-js" */),
  "component---src-templates-faq-template-js": () => import("./../../../src/templates/faq-template.js" /* webpackChunkName: "component---src-templates-faq-template-js" */),
  "component---src-templates-glossary-details-js": () => import("./../../../src/templates/glossary-details.js" /* webpackChunkName: "component---src-templates-glossary-details-js" */),
  "component---src-templates-glossary-landing-js": () => import("./../../../src/templates/glossary-landing.js" /* webpackChunkName: "component---src-templates-glossary-landing-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-investment-details-js": () => import("./../../../src/templates/investment-details.js" /* webpackChunkName: "component---src-templates-investment-details-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-listing-template-js": () => import("./../../../src/templates/listing-template.js" /* webpackChunkName: "component---src-templates-listing-template-js" */),
  "component---src-templates-media-landing-template-js": () => import("./../../../src/templates/media-landing-template.js" /* webpackChunkName: "component---src-templates-media-landing-template-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/news-details.js" /* webpackChunkName: "component---src-templates-news-details-js" */),
  "component---src-templates-news-landing-template-js": () => import("./../../../src/templates/news-landing-template.js" /* webpackChunkName: "component---src-templates-news-landing-template-js" */),
  "component---src-templates-review-template-js": () => import("./../../../src/templates/review-template.js" /* webpackChunkName: "component---src-templates-review-template-js" */),
  "component---src-templates-static-template-js": () => import("./../../../src/templates/static-template.js" /* webpackChunkName: "component---src-templates-static-template-js" */),
  "component---src-templates-team-details-js": () => import("./../../../src/templates/team-details.js" /* webpackChunkName: "component---src-templates-team-details-js" */),
  "component---src-templates-team-landing-template-js": () => import("./../../../src/templates/team-landing-template.js" /* webpackChunkName: "component---src-templates-team-landing-template-js" */)
}

